import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
    {
      pathname: '/campus/',
      crumbLabel: '筑波キャンパスについて'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

  return(
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("沿革")} lang={lang} />
      
      <ContentLayout lang={lang}>
        <h1>{t("沿革")}</h1>
        <ul className="History">
          <li>
            <h2>1984<span className="small">{t("年")}</span></h2>
            <p>{t("ライフサイエンス筑波研究センター（茨城県旧谷田部町：現つくば市）を開設")}</p>
          </li>
          <li>
            <h2>2000<span className="small">{t("年")}</span></h2>
            <p>{t("ライフサイエンス筑波研究センターを筑波研究所へ改称")}</p>
          </li>
          <li>
            <h2>2001<span className="small">{t("年")}</span></h2>
            <p>{t("バイオリソースセンターを開設")}</p>
          </li>
          <li>
            <h2>2003<span className="small">{t("年")}</span></h2>
            <p>{t("特殊法人から独立行政法人へ移行")}</p>
          </li>
          <li>
            <h2>2008<span className="small">{t("年")}</span></h2>
            <p>{t("第2期中期計画スタート")}</p>
          </li>
          <li>
            <h2>2013<span className="small">{t("年")}</span></h2>
            <p>{t("第3期中長期計画スタート")}</p>
            <p>{t("筑波研究所を筑波事業所へ改組")}</p>
          </li>
          <li>
            <h2>2015<span className="small">{t("年")}</span></h2>
            <p>{t("名称を国立研究開発法人理化学研究所へ変更")}</p>
          </li>
          <li>
            <h2>2016<span className="small">{t("年")}</span></h2>
            <p>{t("特定国立研究開発法人へ移行")}</p>
          </li>
          <li>
            <h2>2018<span className="small">{t("年")}</span></h2>
            <p>{t("第4期中長期計画スタート")}</p>
            <p>{t("バイオリソースセンターをバイオリソース研究センターに改称")}</p>
          </li>
        </ul>
      </ContentLayout>
    </Layout>
  )
}
export default Page


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`;